import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AktuellesKlartextPage = () => (
  <Layout>
    <SEO title="AktuellesKlartext" />

    <h1 id="ueberschrift">Gemeinsam lernen und lehren &emsp; Stand:&nbsp;{new Date().getFullYear()}<br/>und andere Informationen </h1>
    <h2>&emsp;------&emsp;</h2>
      <p>&emsp;</p>

      {/* KALENDER?!?!?!
        Konferenz-Termine; auch Ne-Karga, Lese-/Häkel-/Bastel-/etc-gruppe, etc...
        Preise z.B. pro WS und max Teilnehmerzahl angeben
        Achtung: Auch von mir angeleierte WS mit Links auf Weiterbildungs-Seite!
        (deshalb: oben auch Import vom link angegeben)
        !!! unter Weiterbildung nur allgemeine Erklärung zu WS: keine Preise & kein Zeitbedarf !!!
      */}

    <h3>2019-10-21 &emsp; 21. Oktober 2019</h3>
      <p>
        Der Wiederaufbau des Gewerbes dauert noch an.<br />
        Aktuell kann nur die Beratung und der Kummerkasten in vollem Umfang angeboten werden.<br/>
        Veranstaltungen, Kurse und Dienstleistungen finden nur bedingt bzw. nur auf Nachfrage statt.<br />
        Viele Waren und das meiste (Kurs-)Material sind aktuell eingelagert.
      </p>
      <Link to="/fdml_6a_beratung-klartext/" title="Detaillierte Informationen zum Beratungsangebot" style={{fontSize: "1.5em"}}>zum Klartext der Beratung</Link><br />
      <Link to="//fdml_7a_kummerkasten-klartext/" title="Detaillierte Informationen zum Gesprächsangebot" style={{fontSize: "1.5em"}}>zum Klartext des Kummerkastens</Link>
      <p></p>
      <Link to="/fdml_5a_weiterbildung-klartext/" title="Detaillierte Informationen zum Lern- und Verbesserungsangebot" style={{fontSize: "1.5em"}}>Informationen zur Weiterbildung</Link>
      <p></p>

      <p>---</p>

      <div class="b-data">

            {/**Hier vergangene Termine hinein kopieren */}


        <h2>2019-08-24+25 &emsp; 24.+25. August 2019</h2>
          <p>
            <b>Line Dance: Schnuppertreff für Interessierte => Anfängerkurs</b><br/>
            für Teilnehmer der SoCraTes 2019 <br/>
            in Soltau
          </p>
      </div>

  </Layout>
              )

              export default AktuellesKlartextPage
